<template>
  <main class="index">
    <!--    <div class="testLog" style="position:fixed;color: #000; z-index: 100;">{{logMsg}}</div>-->
    <section id="fullpage-wrapper">
      <section class="fullpage home" id="page0">
        <section class="content-wrapper">
          <div class="content">
            <h2 class="headline">
              HOW DO YOU DEFINE <br />
              REAL
            </h2>
            <p class="home-con">
              REAL IS SIMPLY ELECTRICAL SIGNALS <br />
              INTERPRETED BY YOUR BRAIN
            </p>
            <div class="home-highlight"></div>
          </div>
        </section>
      </section>

      <section class="fullpage what-we-do" id="page1">
        <section class="content-wrapper">
          <div class="content">
            <h2 class="headline">What We Do</h2>
            <p>
              <span class="ib"
                >Source is a company focused on VR and AR application.</span
              >
              <span class="ib"
                >Our mission is to create HMD software for everyone’s daily
                use.</span
              >
              <span class="ib"
                >In the future, virtual and realistic boundaries will become
                blurred.</span
              >
              <span class="ib"
                >We believe that in the near future this will change
                everything.</span
              >
            </p>
          </div>
        </section>
      </section>

      <section class="fullpage product" id="page2">
        <section class="content-wrapper">
          <div class="content">
            <a
              class="pa"
              href="https://store.steampowered.com/app/1260320/_/"
              target="_blank"
            >
              <img
                class="normal"
                src="../assets/img/product_PA_normal.png"
                alt=""
              />
              <img
                class="hover"
                src="../assets/img/product_PA_hover.png"
                alt=""
              />
            </a>
            <a href="https://skybox.xyz/get" target="_blank">
              <img
                class="normal"
                src="../assets/img/product_skybox_normal.png"
                alt=""
              />
              <img
                class="hover"
                src="../assets/img/product_skybox_hover.png"
                alt=""
              />
            </a>
          </div>
        </section>
      </section>

      <section class="fullpage careers" id="page2">
        <section class="content-wrapper">
          <div class="content">
            <!--          <div class="slider">
            <ul class="careers-list">
              <li class="career-item-wrapper" v-for="(career, i) in careers" :key="`career-${i}`">
                <router-link class="career-item" :to="`${career.alias}`">
                  <h3 class="career-title">{{ career.position }}</h3>
                  <p class="career-descri">This position is responsible for VR / AR software development which needs to show excellent performance and robustness on different platforms</p>
                  <p class="career-pos">{{ career.place }}</p>
                </router-link>
              </li>
            </ul>
          </div>-->

            <!--          <div class="slider-control">
            <div class="slider-control-dot active"></div>
            <div class="slider-control-dot"></div>
            <div class="slider-control-dot"></div>
          </div>-->

            <splide
              :options="swipeOptions"
              ref="swiper"
              @splide:mounted="onMounted"
            >
              <splide-slide
                class="career-item-wrapper"
                v-for="(career, i) in careers"
                :key="`career-${i}`"
              >
                <router-link class="career-item" :to="`${career.alias}`">
                  <h3 class="career-title">{{ career.position }}</h3>
                  <!--                <p class="career-descri">{{career.descri.substr(0, strSize).replace(/\b[A-Za-z]*$/, '')}}</p>-->
                  <p class="career-descri">{{ getSubstr(career.descri) }}</p>
                  <p class="career-pos">{{ career.place }}</p>
                </router-link>
              </splide-slide>
            </splide>
          </div>
        </section>
      </section>

      <section class="fullpage contact" id="page3">
        <section class="content-wrapper">
          <div class="content">
            <h2 class="headline">CONTACT</h2>
            <p class="contact-con">hello@soutcetech.xyz</p>
          </div>
        </section>
      </section>

      <section class="fullpage service" id="page1">
        <section class="content-wrapper">
          <div class="content">
            <h2 class="headline">Welcome to Our World of Entertainment!</h2>
            <div class="service-con">
              <p>
                Step into a universe where excitement knows no bounds! Our
                services span a wide array of entertainment options designed to
                thrill, engage, and delight.
              </p>
              <p>
                <strong>Electronic Games Services:</strong> Dive into the
                digital realm with our internet-based electronic games, offering
                endless fun and challenges at your fingertips.
              </p>
              <p>
                <strong>Live Musical Performances:</strong> Feel the beat and
                rhythm of live music, where every note tells a story and every
                performance is a celebration of sound.
              </p>
              <p>
                <strong>Contests and Parties:</strong> Engage in the thrill of
                competition with our contests, or celebrate life's moments with
                our expertly organized parties.
              </p>
              <p>
                <strong>Online Gaming:</strong> Explore vast virtual worlds with
                our online video and computer games, where every click brings
                new adventures.
              </p>
              <p>
                <strong>Gaming Tournaments:</strong> Test your skills against
                the best in our computer game tournaments, where strategy and
                quick thinking lead to victory.
              </p>
              <p>
                <strong>Game Organization:</strong> From concept to execution,
                we organize games that bring people together for fun and
                camaraderie.
              </p>
              <p>
                <strong>Entertainment Information:</strong> Stay informed with
                our website's entertainment updates, your go-to source for all
                things fun.
              </p>
              <p>
                <strong>Virtual Reality Gaming:</strong> Immerse yourself in our
                online virtual reality games, where the lines between the real
                and the virtual blur.
              </p>
              <p>
                Join us for an entertainment experience like no other, where
                every service is a gateway to joy and every moment is a
                treasure. Welcome to your new favorite destination for fun!
              </p>
            </div>
          </div>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import { Fullpage } from "@/utils/fullpage";
// import { Splide, SplideSlide } from '@splidejs/vue-splide';
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  name: "Home",
  /*  components: {
    Splide,
    SplideSlide,
  },*/
  data() {
    return {
      anchors: [
        "home",
        "what-we-do",
        "product",
        "careers",
        "contact",
        "service",
      ],
      navClass: ["", "", "", "white", ""],
      strSize: 150,
      fullpage: {},
      swipeOptions: {
        arrows: false,
        rewind: true,
        speed: 1000,
        // width  : 800,
        maxWidth: 1205,
        perPage: 3,
        // margin: 20,
        // gap    : '1rem',
        // gap    : '40px',
        classes: {
          // list: 'careers-list',
          pagination: "slider-control",
          page: "slider-control-dot",
        },
      },
      resizeHandler: null,
      // logMsg: '',
    };
  },
  props: {
    careers: Array,
  },
  watch: {
    $route(to, from) {
      // 对路由变化作出响应...
      // console.log('watch router')
      // console.log(to, from);
      if (to.hash !== from.hash) {
        this.scrollToHash(to.hash);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler, false);
  },
  mounted() {
    this.fullpage = new Fullpage("#fullpage-wrapper", {
      anchors: this.anchors,
      callback: this.updateNav,
      // log: this.log,
    });
    this.updateNav(0);
    this.scrollToHash(window.location.hash);
    this.setStrSize();
    this.resizeHandler = this.windowResize.bind(this);
    window.addEventListener("resize", this.resizeHandler, false);
  },
  methods: {
    log(m) {
      this.logMsg = m;
    },
    onMounted(splide) {
      splide.options = { perPage: this.getSwipePerPage() };
    },
    scrollToHash(hash) {
      let anchor = hash.replace("#", "");
      // console.log('scrollToHash',anchor)
      // console.log(this.fullpage)
      if (anchor && this.anchors.includes(anchor)) {
        // console.log('gotopage by anchor')
        this.fullpage.goToPageByAnchor(anchor);
      } else {
        this.fullpage.goToPageByAnchor(this.anchors[0]);
      }
    },
    updateNav(navClsIndex) {
      // console.log('updateNav', navClsIndex);
      this.$emit("update-nav", this.navClass[navClsIndex]);
    },
    windowResize() {
      let size = this.getSwipePerPage();
      let swiper = this.$refs.swiper;
      let _s = swiper.splide.options.perPage;
      if (size !== _s) {
        swiper.splide.options = { perPage: size };
      }
      this.fullpage.setPages();
      this.setStrSize();
    },
    setStrSize() {
      this.strSize =
        window.innerWidth <= 320 ? 100 : window.innerWidth <= 375 ? 120 : 150;
    },
    getSwipePerPage() {
      return window.innerWidth > 1000 ? 3 : window.innerWidth > 760 ? 2 : 1;
    },
    getSubstr(str) {
      let s;
      let r = this.extractStr(str);
      s = r[0];
      if (s.length < 80) {
        r = this.extractStr(r[1]);
        s += r[0];
      }
      return s;
    },
    extractStr(str) {
      let s;
      let newS = str.replace(/^[^.]+\./, (x) => {
        s = x;
        return "";
      });
      return [s, newS];
    },
  },
};
</script>

<style></style>
