<template>
  <div id="app">
    <header class="header">
      <nav :class="navClass">
        <h1 class="logo" @click="logoHandler">
          <router-link to="/">
            <svg
              version="1.1"
              width="20px"
              class="logo-icon"
              height="40px"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 57.74 57.95"
              style="enable-background: new 0 0 57.74 57.95; line-height: 20px"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M46.91,25.24h10.83C56.12,12.12,45.76,1.7,32.66,0v10.84C39.84,12.35,45.48,18.04,46.91,25.24z M28.87,47.25
			c-8.93,0-16.37-6.36-18.04-14.79H0c1.78,14.37,14.03,25.49,28.87,25.49c14.84,0,27.09-11.12,28.87-25.49H46.91
			C45.24,40.9,37.8,47.25,28.87,47.25z M25.08,10.84V0C11.98,1.7,1.62,12.12,0,25.24h10.83C12.26,18.04,17.9,12.35,25.08,10.84z"
                  />
                </g>
              </g>
            </svg>

            <img
              src="./assets/img/Sourcelogo-1x.png"
              height="40"
              srcset="
                ./assets/img/Sourcelogo-1x.png 1x,
                ./assets/img/Sourcelogo-2x.png 2x
              "
              alt="source technology"
            />

            <img
              src="./assets/img/Sourcelogo-1x-white.png"
              height="40"
              class="logo-white"
              srcset="
                ./assets/img/Sourcelogo-1x-white.png 1x,
                ./assets/img/Sourcelogo-2x-white.png 2x
              "
              alt="source technology"
            />
          </router-link>
        </h1>
        <div class="normal-items">
          <h1 class="nav-item">
            <router-link to="/#service">service</router-link>
          </h1>
          <h1 class="nav-item">
            <router-link to="/#contact">contact</router-link>
          </h1>
          <h1 class="nav-item">
            <router-link to="/#careers">careers</router-link>
          </h1>
          <h1 class="nav-item">
            <a href="https://skybox.xyz/" target="_blank">skybox</a>
          </h1>
          <h1 class="nav-item">
            <a href="https://partyanimals.com/" target="_blank">partyanimals</a>
          </h1>
          <h1 class="nav-item">
            <a href="/#product">product</a>
          </h1>
        </div>
        <div class="mobile-switcher" @click="toggleNav">
          <div class="line-btn" :class="{ close: mobileNavBtnClose }">
            <span class="lines"></span>
          </div>
        </div>
      </nav>
    </header>
    <section
      class="mobile-nav-wrapper"
      :class="{ active: showMobileNav, fadeout: MobileNavFadeout }"
    >
      <div class="mobile-nav-bg"></div>
      <ul class="mobile-nav">
        <li @click="toggleNav">
          <router-link to="/#product" class="mobile-nav-item"
            >product</router-link
          >
        </li>

        <li>
          <a
            class="mobile-nav-item"
            href="https://partyanimals.com/"
            target="_blank"
            >partyanimals</a
          >
        </li>
        <li>
          <a class="mobile-nav-item" href="https://skybox.xyz/" target="_blank"
            >skybox</a
          >
        </li>

        <li @click="toggleNav">
          <router-link to="/#careers" class="mobile-nav-item"
            >Careers</router-link
          >
        </li>
        <li @click="toggleNav">
          <router-link to="/#contact" class="mobile-nav-item last"
            >Contact</router-link
          >
        </li>
        <li @click="toggleNav">
          <router-link to="/#service" class="mobile-nav-item last"
            >Service</router-link
          >
        </li>
      </ul>
    </section>
    <router-view :careers="careers" v-on:update-nav="updateNav"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      navClass: "",
      mobileWidth: 767,
      mobileNavBtnClose: false,
      showMobileNav: false,
      MobileNavFadeout: false,
      careers: [
        {
          id: 0,
          position: "Software Engineer",
          alias: "Software-Engineer",
          place: "BEIJING",
          time: "fulltime",
          descri:
            "This position is responsible for VR / AR software development which needs to show excellent performance and robustness on different platforms. Software Engineer needs to use different methods for software development on different platforms such as Unity or Unreal to build new software feature or new modules.",
          responsibility: "",
          required:
            "Solid understanding of computer science fundamentals, including algorithms, data structures, and design patterns\nExperience programming in C, C++ or C#, and good programming habits\nProficient in Unreal or Unity engines\nKnowledge on memory management, threading and network protocols on Windows, Android or iOS\nCompletely involved and released in software or game development on any platform\nBachelor's degree or above in Computer Science, Mathematics or equivalent experience",
          plus: "Experience with graphics APIs such as DirectX 11/12, OpenGL, Vulkan, or Metal\nExperience with programming Cg / GLSL / HLSL\nOwn a VR / AR device and use it more than one year\nParticipated in development of game engine\nContributing to open source project communities such as Github or Bitbucket",
          materials: "Resume\nCode sample from one of your personal",
        },
        {
          id: 1,
          position: "Senior Engineer",
          alias: "Senior-Engineer",
          place: "BEIJING",
          time: "fulltime",
          descri:
            "Senior engineers needs to improve or define new modules and software architectures, which requires you to have many experiences in C ++ projects. You will be working with development team on multiple platforms in areas like rendering, memory management, file and network I/O",
          responsibility: "",
          required:
            "Advanced understanding of C / C++\nMinimum of 5 years' experience in delivering production-level code in a professional environment\nStrong passion for programming, algorithms, or mathematics\nAbility to communicate effectively with other engineers and designers\nSelf-motivation and willingness to pitch in on many engineering areas\nBachelor's degree or above in Computer Science, Mathematics or equivalent experience",
          plus: "Has served as architecture developer for system or application software\nOwn a VR / AR device and use it more than one year\nContributing to open source project communities such as Github or Bitbucket",
          materials: "Resume\nCode sample from one of your personal",
        },
        {
          id: 2,
          position: "Project Manager",
          alias: "Project-Manager",
          place: "BEIJING",
          time: "fulltime",
          descri:
            "Projects needs to coordinate and control to ensure the progress of the development, this's why project manager's work is essential. You need to connect designers, engineers and market operators, according the roadmap to implement the plan. Project managers need to set milestones and dismantle and allocate projects, which mean you need to know every detail of designing, engineering and marketing, and understand the method and purpose. In order to ensure the project going well",
          responsibility: "",
          required:
            "Minimum of 5 years' work experience in project management, in software, game or website\nExperience utilizing Scrum or Agile development frameworks\nSkilled in risk assessment and management\nExpertise issue tracking software or other online project management tools like teambition\nUnrelenting self-motivation and initiative\nA passion for VR / AR industry\nCapacity to lead without authority\nDedication to continuous and incremental improvements\nFluent in Chinese and English, skilled in verbal and written communication",
          plus: "Bachelor's degree or above in Engineering, Management, Business or equivalent experience\nOwn a VR / AR device and use it more than one year\nContributing to open source project communities such as Github or Bitbucket",
          materials: "Resume",
        },
        {
          id: 3,
          position: "Junior Engineer",
          alias: "Junior-Engineer",
          place: "BEIJING",
          time: "fulltime",
          descri:
            "This position will develop VR / AR application under the leadership of senior software engineers. Junior Engineer is typically responsible for implementing a specific function of the application, network, or framework. These functions are usually developed, but need to be further improved and optimized",
          responsibility: "",
          required:
            "Solid understanding of computer science fundamentals, including algorithms, data structures, and design patterns\nExperience programming in C, C++ or C#, and good programming habits\nProficient in Unreal or Unity engines\nKnowledge on memory management, threading and network protocols on Windows, Android or iOS\nBachelor's degree or above in Computer Science, Mathematics or equivalent experience",
          plus: "Completely involved and released in software or game development on any platform\nOwn a VR / AR device and use it more than one year\nContributing to open source project communities such as Github or Bitbucket",
          materials: "Resume\nCode sample from one of your personal",
        },
        {
          id: 4,
          position: "Graphic Engineer",
          alias: "Graphic-Engineer",
          place: "BEIJING",
          time: "fulltime",
          descri:
            "Performance is as important as image quality in VR / AR. Graphic Engineer needs to make image quality better without losing performance. You will work closely with other engineers, designers to help improve our application graphic technology, analyze the programing to setup and improve the code base",
          responsibility: "",
          required:
            "Extensive experience with realtime / offline graphics rendering technology\nExperience with multi-threaded programming\nExperience with graphics APIs such as DirectX 11/12, OpenGL, Vulkan, or Metal\nExperience programming in C, C++ and good programming habits\nDifferential geometry, trigonometry and linear algebra and other basic knowledge of mathematics\nDeep understanding of tradeoffs in engine performance, scalability and maintainability\nExcellent verbal and written communications skills\nBachelor's degree or above in Computer Science, Mathematics or equivalent experience",
          plus: "Completely involved and released in software or game development on any platform\nOwn a VR / AR device and use it more than one year\nParticipated in development of game engine or 3D graphic engine\nContributing to open source project communities such as Github or Bitbucket",
          materials: "Resume\nCode sample from one of your personal",
        },
        {
          id: 5,
          position: "Community Manager",
          alias: "Community-Manager",
          place: "BEIJING",
          time: "fulltime",
          descri:
            "We are seeking an experienced community manager, in this role you need to have experienced in Facebook, Reddit, twitter and other VR / AR community. You will facing the rookie and hardcore users, communicate with them, listen to needs of them, answer the question for them and feedback to the development team. The ideal candidate is driven, self-motivated, and has many years of experience in community and social media operations",
          responsibility: "",
          required:
            "Minimum one year's experience in community operation in North American\nMinimum one year's experience in brand communications, forums, and social media\nExperience with channel management, publishing calendars, reporting & analytics\nProfessional experience in Word, Excel, PowerPoint and Photoshop\nFamiliarity with project management and process improvement\nAbility to operate and solve problems while working under pressure\nFluent in Chinese and English\nBachelor's degree or above in communications, marketing, or related field or equivalent work experience",
          plus: "English native speaker or studied in North America\nOwn a VR / AR device and use it more than one year",
          materials: "Resume",
        },
        {
          id: 6,
          position: "Codec Engineer",
          alias: "Codec-Engineer",
          place: "BEIJING",
          time: "fulltime",
          descri:
            "In this role you will tasked with finding creative solutions for make the video smaller and provide a better quality on different platforms. The image quality of VR / AR requirements much higher than 4K even 8K resolution, while those image cannot longer be stored locally, even realtime compression and decoding over the network. Those features will face a lot of technical challenges",
          responsibility: "",
          required:
            "Experience with video codec such as MPEG2/4, H264/AVC, or HEVC\nExperience on different platform like Windows, Linux, Android, or iOS\nSolid programming skill and C/C++ coding abilities\nMinimum of 3 years' direct related experience\nStrong analysis, design and debug skills\nMaster's degree or above in Computer Science, Mathematics or equivalent experience\nCompletely involved and released in software development on any platform",
          plus: "PHD's degree or above in Computer Science, Mathematics or equivalent experience\nOwn a VR / AR device and use it more than one year\nContributing to open source project communities such as Github or Bitbucket",
          materials: "Resume\nCode sample from one of your personal",
        },
        {
          id: 7,
          position: "Business Manager",
          alias: "Business-Manager",
          place: "BEIJING",
          time: "fulltime",
          descri:
            "Business Manager is responsible for establish linkages and reach cooperative with our clients or other third parties. Business Manager responsible for establishing strategic third-party relationships for the System, Hardware, Content Provider Company that drive growth through sponsorships, alliances, marketing partnerships, and other forms of partnership. Communication and Planning skills are particularly important for this position, you need to have a self-motivated personality and have enough enthusiasm for VR and AR",
          responsibility: "",
          required:
            "Understand VR and AR Industry\nMinimum of one year's experience in business development of application software\nProfessional experience in Word, Excel, PowerPoint and Photoshop\nProven ability to communicate with Manager, Partner and other team\nAbility to promote teamwork crosses teams, companies\nAbility to operate and solve problems while working under pressure\nFluent in Chinese and English\nBachelor's degree or above",
          plus: "English native speaker\nExperience in business development of application or game in the United States\nOwn a VR / AR device and use it more than one year",
          materials: "Resume",
        },
      ],
    };
  },
  mounted() {
    this.windowResize();
    window.addEventListener("resize", this.windowResize, false);
  },
  methods: {
    /*
      @param type {string}: (''|'white'|'fixed'|'mobile')
    */
    updateNav(type) {
      let width = window.innerWidth;
      if (width <= this.mobileWidth) {
        this.navClass = type + " mobile";
      } else {
        this.navClass = type;
      }
    },

    windowResize() {
      if (window.innerWidth <= this.mobileWidth) {
        if (!/mobile/.test(this.navClass)) {
          this.navClass += " mobile";
        }
      } else {
        if (/mobile/.test(this.navClass)) {
          this.navClass = this.navClass.replace(" mobile", "");
        }
      }
    },

    logoHandler() {
      if (this.showMobileNav) {
        this.hideNav();
      }
    },

    toggleNav() {
      if (!this.showMobileNav) {
        this.mobileNavBtnClose = true;
        this.showMobileNav = true;
      } else {
        this.hideNav();
      }
    },
    hideMobileNav() {
      this.hideNav();
    },
    hideNav() {
      this.mobileNavBtnClose = false;
      this.MobileNavFadeout = true;
      setTimeout(() => {
        this.MobileNavFadeout = false;
        this.showMobileNav = false;
      }, 900);
    },
  },
};
</script>

<style>
@import "assets/css/common.css";
</style>
